<template>
  <div class="page-coantainer">
    <el-dialog
      title="添加推广员"
      :visible.sync="addData.isOpen"
      width="30%"
      :before-close="handleClose"
    >
      <div class="dialog-body">
        <el-form ref="form" :model="formData" :rules="rules" label-width="80px">
          <el-form-item label="姓名" prop="name">
            <el-input v-model.trim="formData.name"></el-input>
          </el-form-item>
          <el-form-item label="手机" prop="phone">
            <el-input v-model.trim="formData.phone"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="submitAddMajor">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { addPromoter } from '@/api/api.js'

export default {
  props: {
    addData: {
      type: Object
    }
  },
  data () {
    // 验证手机号的规则
    // const checkMobile = (rule, value, cb) => {
    //   // 验证手机号的正则表达式
    //   const regMobile = /^(0|86|17951)?(13[0-9]|15[0123456789]|17[678]|18[0-9]|14[57])[0-9]{8}$/
    //   if (regMobile.test(value)) {
    //     return cb()
    //   }
    //   cb(new Error('请输入合法手机号'))
    // }
    return {
      isOpen: false,
      formData: {
        name: '',
        phone: '',
        qrCode: ''
      },
      schoolList: [],
      rules: {
        name: [
          { required: true, message: '请填写姓名', trigger: 'blur' },
          { min: 2, max: 6, message: '长度在 2 到 6 个字符', trigger: 'blur' }
        ]
        // phone: [
        //   { required: true, message: '请填写手机号码', trigger: 'blur' },
        //   { validator: checkMobile, trigger: 'blur' }
        // ]
      }
    }
  },
  methods: {
    /* 添加专业 */
    submitAddMajor () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          addPromoter(this.formData).then(res => {
            this.$emit('closeAddDialog', { isOpen: false, type: 'success' })
            this.$refs.form.resetFields()
            this.$message({
              message: '添加成功',
              type: 'success'
            })
          })
        }
      })
    },
    /* 关闭弹窗 */
    handleClose () {
      this.$emit('closeAddDialog', { isOpen: false })
    }
  }
}
</script>

<style lang="scss" scoped>
.page-coantainer {
  ::v-deep .el-dialog {
    &__body {
      max-height: 500px;
      overflow: auto;
    }
  }
}
</style>
